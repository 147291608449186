<template>
  <div>
    <div class="company-profile-basic-details-wrapper">
      <div class="company-profile-basic-details">
        <div class="company-profile-basic-details-section-1">
          <a-avatar shape="square" :size="100">
           <UserOutlined  style="fontSize : 5rem" class="team-member-image"/>
          </a-avatar>
        </div>
        <div class="company-profile-basic-details-section-2">
          <div>
              <div style="display: flex;">
            <span
              >{{ this.profileDetails.firstName }}
              {{ this.profileDetails.lastName }}</span
            >
          
            <span class="spacer company-profile-basic-details-2-active-tag"
              >Active</span
            >
            
            <span class="add-new-turf-selected-tag" v-for="name in this.profileDetails.teams" :key="name"
              >{{ name.name }}</span
            >

            <span class="add-new-turf-selected-tag" v-for="name in this.profileDetails.subTeams" :key="name"
              >{{ name.name }}</span
            >
            </div>
          </div>

          <div style="margin-top: 7px">
            <span style="font-size: 12px">Role: {{ this.profileDetails.role ? this.profileDetails.role.name : '' }}</span>
          </div>

          <div style="margin-top: 7px">
            <span style="font-size: 12px">Designation: AC</span>
          </div>

          <div style="margin-top: 10px; display: flex">
            <div class="spacer-right">
              <span style="display: block; font-size: 10px">Joining</span>
              <span style="color: rgba(0, 0, 0, 0.76); font-size: 10px">{{
                displayJoiningDate()
              }}</span>
            </div>
            <div class="spacer-right">
              <span style="display: block; font-size: 10px">Salary</span>
              <span style="color: rgba(0, 0, 0, 0.76); font-size: 10px"
                >Rs. {{ this.profileDetails.salary }}</span
              >
            </div>
          </div>
        </div>
        <div class="company-profile-basic-details-section-3">
          <div style="margin-top: 5px">
            <div
              class="team-member-details"
              style="color: rgba(0, 0, 0, 0.76); font-size: 14px"
            >
              Mobile Number
            </div>
            <div>{{ this.profileDetails.mobile }}</div>
          </div>
          <div style="margin-top: 5px">
            <div class="team-member-details" style="color: rgba(0, 0, 0, 0.76); font-size: 14px;">
              Phone Number
            </div>
            <div>{{ this.profileDetails.phone }}</div>
          </div>
          <div style="margin-top: 5px">
            <div class="team-member-details" style="color: rgba(0, 0, 0, 0.76); font-size: 14px">
              Login Email ID
            </div>
            <div>{{ this.profileDetails.email }}</div>
          </div>
        </div>

        <div class="company-profile-basic-details-section-4">
        </div>
      </div>
    </div>

    <div class="team-member-profile-section-2">
      <div style="width: 100%; display: flex">
        <div style="flex: 2">Login Information</div>
        <div style="flex: 1">Access</div>
      </div>
    </div>
    <div class="team-member-profile-section-3">
      <div
        style="
          background: #ffffff;
          margin-right: 10px;
          flex: 2;
          box-shadow: $card-box-shadow;
          padding: 20px;
          border-radius: 10px;
        "
      >
        <div style="display: flex; justify-content: space-around">
          <div>
            Login Name: {{ this.profileDetails.firstName }}
            {{ this.profileDetails.lastName }}
          </div>
          <div>Login Alias: {{ this.profileDetails.loginAlias }}</div>
        </div>
        <div style="margin-top: 20px; border-radius: 10px">
          Login View:
          <div v-if="this.profileDetails.loginView" style="padding-top: 10px; display: flex">
            <a-tab
              v-model="this.profileDetails.loginView"
              v-for="(view, viewIndex) in this.viewOptions"
              :key="viewIndex"
              :class="
                 view.name.toLowerCase() === this.profileDetails.loginView.toLowerCase()
                  ? 'add-new-turf-selected-tag'
                  : 'add-new-turf-tag'
              "
              >{{view.name}}</a-tab
            >
          </div>
        </div>

        <div style="margin-top: 20px">
          Login Role:
          <div v-if="this.profileDetails.role && this.profileDetails.role.name" style="padding-top: 10px; display: flex">
            <a-tab
              
              v-for="(role, roleIndex) in rolesOptions"
              :key="roleIndex"
              :class="
                role.name.toLowerCase() === this.profileDetails.role.name.toLowerCase()
                  ? 'add-new-turf-selected-tag'
                  : 'add-new-turf-tag'
              "
              >{{ role.name }}</a-tab
            >
          </div>
        </div>
      </div>
      <div class="create-company-created-by-wrapper">
        <div class="create-company-created-by-sub-div-wrapper">
          <div class="create-company-created-by-text-2">Access Details</div>
          <div class="allowed-login-text">Login Allowed On:</div>
          <div class="employement-and-department-wrapper">
            <div style="padding-top: 10px">
              <a-form-item>
                <a-row>
                  <div
                    @click="addDayToWeek('', 'sunday')"
                    style="margin: 0 5px 0 0"
                    :class="
                      days.sunday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    S
                  </div>
                  <div
                    @click="addDayToWeek('', 'monday')"
                    style="margin: 0 5px"
                    :class="
                      days.monday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    M
                  </div>
                  <div
                    @click="addDayToWeek('', 'tuesday')"
                    style="margin: 0 5px"
                    :class="
                      days.tuesday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    T
                  </div>
                  <div
                    @click="addDayToWeek('', 'wednesday')"
                    style="margin: 0 5px"
                    :class="
                      days.wednesday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    W
                  </div>
                  <div
                    @click="addDayToWeek('', 'thursday')"
                    style="margin: 0 5px"
                    :class="
                      days.thursday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    T
                  </div>
                  <div
                    @click="addDayToWeek('', 'friday')"
                    style="margin: 0 5px"
                    :class="
                      days.friday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    F
                  </div>
                  <div
                    @click="addDayToWeek('', 'saturday')"
                    style="margin: 0 5px"
                    :class="
                      days.saturday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    S
                  </div>
                </a-row>
              </a-form-item>
            </div>
            <div style="display: flex">
              <span style="color: #f5222d" v-if="DaysErrorMsg">{{
                DaysErrorMsg
              }}</span>
            </div>
          </div>

          <div style="justify-content: space-between">
            <div>
              Time In: <span>{{this.profileDetails.acl && this.profileDetails.acl.startTime}}</span>
              <!-- <a-time-picker v-model:value="value" format="HH:mm" :min="30" /> -->
            </div>
            <div style="margin-top: 10px; margin-bottom: 10px">
              Time Out:  <span>{{this.profileDetails.acl && this.profileDetails.acl.endTime}}</span>
              <!-- <a-time-picker v-model:value="value2" format="HH:mm" :min="30" /> -->
            </div>
             <div>Valid Till: <span>{{displayValidityDate()}}</span></div> 
          </div>
          <div class="create-team-member-note">
            Note: For no restriction on time set Time in =0= Time out
          </div>
          <div
            style="
              display: flex;
              justify-content: space-evenly;
              margin-top: 30px;
            "
          >
             <div><a-checkbox :checked="this.profileDetails.acl && this.profileDetails.acl.isMacCheckEnabled && this.profileDetails.acl.isMacCheckEnabled?true:false">Mac check</a-checkbox></div>
            <div><a-checkbox :checked="this.profileDetails.acl && this.profileDetails.acl.isIpCheckEnabled && this.profileDetails.acl.isIpCheckEnabled?true:false">IP check</a-checkbox></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Services from "@/services/apis";
import dayjs from "dayjs";
import { UserOutlined } from "@ant-design/icons-vue";
import moment from "moment";

export default {
  components: {
    UserOutlined
    // PlusOutlined,
    // UploadOutlined
  },
  data() {
    return {
      // value1,
      days: {
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      },
      addSchedule: [],
      DaysErrorMsg: "",
      viewOptions: [
        { index: 0, name: "Company" },
        { index: 1, name: "Site" },
        { index: 2, name: "Team" },
        { index: 3, name: "CIC" },
      ],
      rolesOptions: [
        { index: 0, name: "Account" },
        { index: 1, name: "Administrator" },
        { index: 2, name: "HR Team" },
        { index: 3, name: "DMC" },
        { index: 4, name: "BFSI" },
        { index: 5, name: "Principal above" },
      ],
      // value1,
      selectedTabIndexes: [],
      profileDetails: {},
      moment,
      // section: 1,
    };
  },

  //   mounted: {},
  mounted() {
    const { id } = this.$route.params;
    this.teamMemberProfile(id);
  },
  methods: {
    onClickTab(index) {
      if (this.selectedTabIndexes.includes(index)) {
        const arrayIndex = this.selectedTabIndexes.findIndex(
          (turf) => turf === index
        );
        this.selectedTabIndexes.splice(arrayIndex, 1);
      } else {
        this.selectedTabIndexes.push(index);
      }
    },
    addDayToWeek(arrayIndex, day) {
      if (arrayIndex.length === 0) {
        //  this.DaysErrorMsg = "Please select at least one day.";
        this.days[day] = !this.days[day];
      } else {
        this.addSchedule.map((x, index) => {
          if (index === arrayIndex) {
            this.addSchedule[arrayIndex].days[day] =
              !this.addSchedule[arrayIndex].days[day];
          }
        });
      }
      if (this.checkAtLeastOneDaySelected()) {
        this.DaysErrorMsg = "Please select at least one day.";
        return false;
      } else {
        this.DaysErrorMsg = "";
      }
    },
    checkAtLeastOneDaySelected() {
      let selectedDay = Object.keys(this.days).every((k) => !this.days[k]);
      return selectedDay;
    },

    teamMemberProfile(id) {
      Services.getTeamMemberProfile(id)
        .then((response) => {
          const { data } = response;

          console.log("Team Member Profile data :>> ", data);
          this.profileDetails = data;

          this.profileDetails.acl.days.map((day) => {
            this.days[day.toLowerCase()] = !this.days[day.toLowerCase()];
          });
          this.$forceUpdate();

          console.log(
            "this.profileDetails.loginView :>> ",
            this.profileDetails.loginView
          );
          // this.profileDetails.loginView.map((view) => {
          //   this.viewOptions[view.toLowerCase()] =
          //     !this.viewOptions[view.toLowerCase()];
          // });

          //this.profileDetails.loginView = ![this.profileDetails.loginView];
          // this.profileDetails.loginView.toString();
          //this.selectedTabIndexes.push(this.profileDetails.loginView);

          console.log("this.days :>> ", this.profileDetails.loginView);

          //  this.profileDetails.acl

          //   data &&
          //     data.length &&
          //     data[0].candidates.map((x) => {
          //       this.candidatesData.push({
          //         icon: x.imageUrl,
          //         serialNo: +1,
          //         candidateName: {
          //           name: x.name,
          //           resumeId: x.resumeId,
          //           experience: x.experience,
          //           socialAccounts: x.socialAccounts,
          //         },
          //         education: x.education,
          //         testName: [x.practiceArea, x.subPracticeArea],
          //         notes: x.notesCount,
          //         twCount: x.totalWorkExp,
          //         docCount: x.documentsCount,
          //         ctc: x.ctc,
          //         comments: x.comments,
          //         status: x.status,
          //         cic: x.cic,
          //       });
          //       this.allBookingsCount = data[0].count;

          //       this.paginationValues = {
          //         total: data[0].count,
          //         current: this.scrollIncrement,
          //       };
          //       this.loading = false;
          //     });
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },

    displayJoiningDate(){
            return dayjs (this.profileDetails.joiningDate).format("DD/MM/YYYY");
    },
    displayValidityDate(){
            return dayjs (this.profileDetails.validity).format("DD/MM/YYYY");
    }
    // moment,
    // onClickCreate() {
    //   this.section++;
    // },
  },
};
</script>


<style lang="scss" scoped>
@import "../styles/teamMemberProfile.scss";
</style>